<template>
  <div>
    <div class="container my-auto">
      <b-card class="my-auto">

        <div class="p-2">
          <div class="b-card__title mb-1">
            <h4 class="mb-2">
              Thank you for choosing HeadHunters HQ
            </h4>
            <p>
              We have received your payment successfully and we look
              forward to building more solutions that seeks to add value.
              <br>
              <br>
              You will now be redirected back to the Dashboard. If you are
              not redirected within the next few minutes, please click on
              the button below.
            </p>
          </div>
          <b-button
            variant="primary"
            type="border"
            :to="back_url"
            class="mx-auto"
          >Go back to the Dashboard</b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      payment_id: 0,
      success_code: 0,
      quantity: 0,
      platform: '',
      amount: 0,
      back_url: '/analytics/organization',
    }
  },
  created() {
    const { query } = this.$router.currentRoute
    if (query.pq) {
      const open_data = window.atob(query.pq)
      this.amount = open_data.split('-')[2]
      this.quantity = open_data.split('-')[1]
      this.platform = open_data.split('-')[0]
    }
    this.payment_id = this.$route.params.product_code
    this.success_code = parseInt(this.$route.params.success_code)
    this.updateAccountStatus()
    // const query = this.$router.currentRoute.query;
  },
  methods: {
    updateAccountStatus() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      let sending_data = {}
      if (this.success_code == 100) {
        sending_data = {
          market_presence: {
            active: true,
            amount: 75,
            payment_id: this.payment_id,
            message: `
We are analysing your Organisation's Market Presence. The information will be available within three (3) working days. Please check back later.

For enquiries, please contact us via contactus@headhuntershq.com. Thank you.`,
          },
        }
      } else if (this.success_code == 200) {
        sending_data = {
          market_sentiment: {
            active: true,
            amount: 150,
            payment_id: this.payment_id,
            message: 'Your market sentiment score will be available shortly.',
          },
        }
      } else if (this.success_code == 300) {
        sending_data = {
          corporate_directory: {
            active: true,
            amount: 20,
            payment_id: this.payment_id,
            message:
              'The corporate directory has been activated for your organization.',
          },
        }
      } else if (this.success_code == 500) {
        this.back_url = '/applicant-tracking/edit-job'
        sending_data = {
          ats_credits: {
            quantity: this.quantity,
            platform: this.platform,
            payment_id: this.payment_id,
            amount: this.amount,
          },
        }
      }

      this.$http
        .post('/api/account-status', {
          data: sending_data,
        })
        .then(response => {
          if (response.data.success) {
            if (this.success_code === 500) {
              this.$router.push('/applicant-tracking/edit-job').catch(() => {})
            } else {
              this.$router.push('/analytics/organization').catch(() => {})
            }
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Error detected',
                icon: 'AlertCircleIcon',
                text: 'Could not be able to update your update your dashboard. Please contact support: contactus@headhuntershq.com',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
  },
}
</script>
